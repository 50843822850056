<template>
	<div>
		<b-card>
			<h4>Versandkosten</h4>
			<div>Versandkosten pro Box: {{ variables.SHIPPING_BOX_PRICE }}€</div>

			<div v-if="variables.SHIPPING_PRICES">
				<div>Versandkosten pro Box (Duplikat?): {{ variables.SHIPPING_PRICES.PACKAGE }}€</div>
				<div class="mt-1">
					<div class="font-bold">Textilmengen pro Kategorie/Box:</div>
					<div class="ml-1">
						<div v-for="(category, idx) in variables.SHIPPING_PRICES.CATEGORIES">
							<span class="text-capitalize">{{ category }}</span
							>: {{ variables.SHIPPING_PRICES.AMOUNTS[idx] }} Stück
						</div>
					</div>
				</div>
			</div>
		</b-card>
		<b-card class="my-3">
			<h4>Druckgrößen</h4>
			<div>maximale Druck-Breite: {{ variables.MAX_PRINT_WIDTH }}cm</div>
			<div>maximale Druck-Höhe: {{ variables.MAX_PRINT_HEIGHT }}cm</div>
		</b-card>
		<b-card class="my-3">
			<h4>Größe A4 in cm</h4>
			<div>A4-Breite: {{ variables.A4_WIDTH }}cm</div>
			<div>A4-Höhe: {{ variables.A4_HEIGHT }}cm</div>
		</b-card>
		<b-card class="my-3">
			<h4>Pufferkosten:</h4>
			<div>Puffer in %: {{ variables.PUFFER_PERCENTAGE }}%</div>
		</b-card>
		<b-card class="my-3">
			<h4>Druckkosten:</h4>
			<div>Farbwechsel: {{ variables.COLOR_CHANGE_PRICE }}€</div>
			<div>Rahmenwechsel: {{ variables.FRAME_CHANGE_PRICE }}€</div>

			<div v-if="variables.PRINT_PRICES && variables.PRINT_PRICES.LIGHT_ON_DARK">
				<div class="mt-1">
					<div class="font-bold">Kosten für Print (hell auf dunkel):</div>
					<div class="ml-1">
						<div v-for="(color, cIdx) in variables.PRINT_PRICES.LIGHT_ON_DARK.PRINT_COLORS" :key="cIdx">
							<div v-for="(amount, aIdx) in variables.PRINT_PRICES.LIGHT_ON_DARK.AMOUNT" :key="aIdx">
								<span class="text-capitalize">{{ color }} Farben</span> /
								<span class="text-capitalize">{{ amount }} Stück</span>:
								{{ variables.PRINT_PRICES.LIGHT_ON_DARK.PRICES[cIdx][aIdx] }}€
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="variables.PRINT_PRICES && variables.PRINT_PRICES.DARK_ON_LIGHT">
				<div class="mt-1">
					<div class="font-bold">Kosten für Print (dunkel auf hell):</div>
					<div class="ml-1">
						<div v-for="(color, cIdx) in variables.PRINT_PRICES.DARK_ON_LIGHT.PRINT_COLORS" :key="cIdx">
							<div v-for="(amount, aIdx) in variables.PRINT_PRICES.DARK_ON_LIGHT.AMOUNT" :key="aIdx">
								<span class="text-capitalize">{{ color }} Farben</span> /
								<span class="text-capitalize">{{ amount }} Stück</span>:
								{{ variables.PRINT_PRICES.DARK_ON_LIGHT.PRICES[cIdx][aIdx] }}€
							</div>
						</div>
					</div>
				</div>
			</div>
		</b-card>
		<b-card class="my-3" v-if="variables.PRINT_SCREEN_SETUP_PRICES">
			<h4>Siebeinrichtung:</h4>
			<div class="mt-1">
				<div v-for="(size, idx) in variables.PRINT_SCREEN_SETUP_PRICES.SIZES">
					<span class="text-capitalize">{{ size }}</span
					>: {{ variables.PRINT_SCREEN_SETUP_PRICES.PRICES[idx] }} €
				</div>
			</div>
		</b-card>
		<b-card class="my-3" v-if="variables.PALETT_TYPES">
			<h4>Paletten:</h4>
			<div>Palettenwechsel: {{ variables.PALETT_CHANGE_PRICE }}€</div>
			<div class="mt-1">Typen:</div>
			<div>Typ 1: {{ variables.PALETT_TYPES.ONE }}</div>
			<div>Typ 2: {{ variables.PALETT_TYPES.TWO }}</div>
		</b-card>
		<b-card class="my-3" v-if="variables.TEXTILE_SURPLUSAMOUNTS">
			<h4>Textilien:</h4>
			<div>Standard-Überschuss pro Textilfarbe bei Print: {{ variables.TEXTILE_SURPLUSAMOUNTS.PRINT }} Stück</div>
			<div>Standard-Überschuss pro Textilfarbe bei Stick: {{ variables.TEXTILE_SURPLUSAMOUNTS.STICK }} Stück</div>
		</b-card>
		<b-card class="my-3">
			<h4>Stickkosten:</h4>
			<div>Standardpreis: {{ variables.STICK_STANDARD_PRICE }}€</div>

			<div v-if="variables.STICK_PRICES">
				<div class="mt-1">
					<div class="font-bold">Kosten für Stick (normal):</div>
					<div class="ml-1">
						<div v-for="(amount, idx) in variables.STICK_PRICES.NORMAL.AMOUNT">
							<span class="text-capitalize">{{ amount }} Stück</span>: {{ variables.STICK_PRICES.NORMAL.PRICES[idx] }} €
						</div>
					</div>
				</div>
				<div class="mt-1">
					<div class="font-bold">Kosten für Stick (3d-Sticl):</div>
					<div class="ml-1">
						<div v-for="(amount, idx) in variables.STICK_PRICES['3D_STICK'].AMOUNT">
							<span class="text-capitalize">{{ amount }} Stück</span>: {{ variables.STICK_PRICES['3D_STICK'].PRICES[idx] }} €
						</div>
					</div>
				</div>
			</div>

			<div v-if="variables.STICK_FILE_PRICES">
				<div class="mt-1">
					<div class="font-bold">Kosten für Stickdatei:</div>
					<div class="ml-1">
						<div v-for="(size, idx) in variables.STICK_FILE_PRICES.SIZE">
							<span class="text-capitalize">{{ size }}</span
							>: {{ variables.STICK_FILE_PRICES.PRICES[idx] }} €
						</div>
					</div>
				</div>
			</div>
		</b-card>
	</div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import { mapFields } from 'vuex-map-fields'

export default {
	name: 'CalcVariables',
	components: { BCard },
	computed: {
		...mapFields('CalcVariables', { variables: 'variables' }),
	},
	mounted() {
		this.$store.dispatch('CalcVariables/fetchVariables')
	},
}
</script>
